/** @jsx jsx */
import { jsx, Container } from "theme-ui";
import PropTypes from "prop-types";

export default function Layout({ children }) {
  return (
    <Container sx={{ variant: "KanhaBookingMainPage" }}>{children}</Container>
  );
}

Layout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
